import * as constants from './constants';
import { HYDRATE } from 'next-redux-wrapper';
const initialState = {
	displayModeList: false,
	lectures: [],
	error: {},
};
function setDisplayMode(state, action) {
	const newState = { ...state };

	newState.displayModeList = action.payload;
	return newState;
}
export default function searchReducer(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			const nextState = {
				...state, // use previous state
				...action.payload, // apply delta from hydration
			};

			if (typeof window !== 'undefined' && state.router) {
				// preserve router value on client side navigation
				nextState.router = state.router;
			}
			return nextState;
		case constants.DISPLAY_MODE:
			return setDisplayMode(state, action);

		default:
			return state;
	}
}
