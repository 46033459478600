// import { combineReducers } from 'redux';

// // REMOVE THIS:
// import todoReducer from './modules/todo/reducer';
// import authReducer from './modules/auth/reducer';

// // State-ul global va arata asa:
// /**
//  * {
//  *  todos: {
//  *    list: []  // ce pui ca si initial state in reducer, apare aici
//  *  }
//  * }
//  */
// export default combineReducers({
// 	// REMOVE THIS:
// 	todos: todoReducer,
// 	auth: authReducer,
// });
import { combineReducers } from 'redux';
import authReducer from './modules/auth/reducer';
import activitiesReducer from './modules/activities/reducer';
import searchReducer from './modules/search/reducer';
import superStylingReducer from './modules/superStyling/reducer';
import { firebaseReducer } from 'react-redux-firebase';
const rootReducer = combineReducers({
	superStyling: superStylingReducer,
	auth: authReducer,
	activities: activitiesReducer,
	search: searchReducer,
	// session: sessitonReducer
});

export default rootReducer;
