import * as constants from './constants';
import { HYDRATE } from 'next-redux-wrapper';
const initialState = {
	bgColor: '#000',
	navLight:false,
	visibleNav: true,
};
function setBgColor(state, action) {
	const newState = { ...state };

	newState.bgColor = action.payload;
	return newState;
}
function setNavLight(state, action) {
	const newState = { ...state };

	newState.navLight = action.payload;
	return newState;
}
function setVisibleNav(state, action) {
	const newState = { ...state };

	newState.visibleNav = action.payload;
	return newState;
}
export default function superStylingReducer(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			const nextState = {
				...state, // use previous state
				...action.payload, // apply delta from hydration
			};

			if (typeof window !== 'undefined' && state.router) {
				// preserve router value on client side navigation
				nextState.router = state.router;
			}
			return nextState;

		case constants.CHANGE_BG_COLOR:
			return setBgColor(state, action);
		case constants.NAV_LIGHT:
			return setNavLight(state, action);
		case constants.VISIBLE_NAV:
			return setVisibleNav(state, action);

		default:
			return state;
	}
}
