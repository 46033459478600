import * as constants from './constants';
import { HYDRATE } from 'next-redux-wrapper';
const initialState = {
	user: {},
	error: {},
};
function signin(state, action) {
	const newState = { ...state };

	newState.user = action.payload;
	return newState;
}
function localSignin(state, action) {
	const newState = { ...state };

	newState.user = action.payload;
	return newState;
}
function error(state, action) {
	const newState = { ...state };

	newState.error = action.payload.message;

	return newState;
}
function signout(state) {
	const newState = { ...state };

	newState.user = {};

	return newState;
}
export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			const nextState = {
				...state, // use previous state
				...action.payload, // apply delta from hydration
			};

			if (typeof window !== 'undefined' && state.router) {
				// preserve router value on client side navigation
				nextState.router = state.router;
			}
			return nextState;
		case constants.AUTH_SIGNIN:
			// return { ...state, token: action.payload };
			return signin(state, action);
		case constants.AUTH_LOCAL_SIGNIN:
			// return { ...state, token: action.payload };
			return localSignin(state, action);
		case constants.ERROR:
			// return { ...state, token: action.payload };
			return error(state, action);
		case constants.AUTH_SIGNOUT:
			// return { token: null };
			return signout(state);
		default:
			return state;
	}
}
