// import { applyMiddleware, compose, createStore } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import { createWrapper } from 'next-redux-wrapper';
// import monitorReducersEnhancer from './enhancers/monitorReducers';
// import loggerMiddleware from './middlewares/logger';
// import rootReducer from './rootReducer';
// import { PRODUCTION } from '../config';

// const isDev = PRODUCTION;
// const middlewares = [thunkMiddleware];

// if (isDev) middlewares.push(loggerMiddleware);

// const middlewareEnhancer = applyMiddleware(...middlewares);
// const enhancers = [middlewareEnhancer];

// if (isDev) enhancers.push(monitorReducersEnhancer);

// const composedEnhancers = isDev
// 	? composeWithDevTools(...enhancers)
// 	: compose(...enhancers);

// const makeStore = () => createStore(rootReducer, composedEnhancers);
// // const store = createStore(rootReducer, preloadedState, composedEnhancers);

// export const wrapper = createWrapper(makeStore);

// import { createStore, applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import thunkMiddleware from 'redux-thunk';
// import rootReducer from './rootReducer';
// import { createWrapper } from 'next-redux-wrapper';
// import { reduxFirestore, getFirestore } from 'redux-firestore';
// import { getFirebase } from 'react-redux-firebase';
// import fire from '../config/firebase';
// import { PRODUCTION } from '../config';
// const bindMiddleware = (middleware) => {
// 	if (!PRODUCTION) {
// 		const { composeWithDevTools } = require('redux-devtools-extension');
// 		return composeWithDevTools(applyMiddleware(...middleware));
// 	}
// 	return applyMiddleware(...middleware);
// };

// const initStore = () => {
// 	return createStore(rootReducer, bindMiddleware([thunkMiddleware]));
// };

// export const wrapper = createWrapper(initStore);

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import { PRODUCTION } from '../config';
import { createWrapper } from 'next-redux-wrapper';
import { getFirestore, reduxFirestore } from 'redux-firestore';
import { getFirebase, reactReduxFirebase } from 'react-redux-firebase';
import { firebaseConfig } from '../config/firebase';
const bindMiddleware = (middleware) => {
	if (!PRODUCTION) {
		const { composeWithDevTools } = require('redux-devtools-extension');
		return composeWithDevTools(applyMiddleware(...middleware));
	}
	return applyMiddleware(...middleware);
};

const store = createStore(
	rootReducer,
	compose(
		bindMiddleware([thunk.withExtraArgument({ getFirebase, getFirestore })]),
		reduxFirestore(firebaseConfig)
	)
);
const makeStore = () => store;
export const wrapper = createWrapper(makeStore);
