import * as constants from './constants';
import { HYDRATE } from 'next-redux-wrapper';
const initialState = {
	activities: {},
	lectures: [],
	modal: {},
	displayActivites: {
		levels: [],
	},
	finished: false,
	error: {},
};

function saveActivity(state, action) {
	const newState = { ...state };

	newState.activities[action.payload.activity + '-' + action.payload.slug] =
		action.payload;
	return newState;
}
function setLectures(state, action) {
	const newState = { ...state };

	newState.lectures = action.payload;
	return newState;
}
function setModal(state, action) {
	const newState = { ...state };
	newState.modal = action.payload;
	return newState;
}
function setFinishActivity(state, action) {
	const newState = { ...state };
	newState.finished = !newState.finished;
	return newState;
}
function setDisplayActivities(state, action) {
	const newState = { ...state };
	newState.displayActivites = action.payload;
	return newState;
}
export default function activitiesReducer(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			const nextState = {
				...state, // use previous state
				...action.payload, // apply delta from hydration
			};

			if (typeof window !== 'undefined' && state.router) {
				// preserve router value on client side navigation
				nextState.router = state.router;
			}
			return nextState;
		case constants.SAVE_IMAGE_DND:
			return saveActivity(state, action);
		case constants.SAVE_TEXT_DND:
			return saveActivity(state, action);
		case constants.SAVE_DRAW:
			return saveActivity(state, action);
		case constants.SAVE_IMAGE_UPLOAD:
			return saveActivity(state, action);
		case constants.SAVE_ANSWERS:
			return saveActivity(state, action);
		case constants.SAVE_TEXT_WITH_IMAGES:
			return saveActivity(state, action);
		case constants.SAVE_CLASSIFY_IMAGES:
			return saveActivity(state, action);
		case constants.SAVE_DND_VERTICAL_IMAGES:
			return saveActivity(state, action);
		case constants.SAVE_DRAW_WITH_TEXT:
			return saveActivity(state, action);
		case constants.SAVE_TEXT_DND_WITH_DRAW_AND_TEXT:
			return saveActivity(state, action);
		case constants.SAVE_IMAGE_DND_WITH_TEXT:
			return saveActivity(state, action);
		case constants.SAVE_IMAGE_UPLOAD_WITH_TEXT:
			return saveActivity(state, action);
		case constants.GET_ACTIVITES:
			return setLectures(state, action);
		case constants.SHOW_MODAL:
			return setModal(state, action);
		case constants.FINISH_ACTIVITY:
			return setFinishActivity(state, action);
		case constants.DISPLAY_ACTIVITIES:
			return setDisplayActivities(state, action);

		default:
			return state;
	}
}
